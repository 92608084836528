import axios from "axios";
import { injectable } from "inversify";

import * as models from "@/domain/robots/models";
import * as gateways from "@/adapters/gateways/robots";
import * as constants from "@/commons/constants";

@injectable()
export class RobotServerGateway implements gateways.IRobotGateway {
  URL: string = `${constants.SERVER_ADDRESS}/robots/robot`;

  list(): Promise<Array<models.Robot>> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  filter(query: any): Promise<Array<models.Robot>> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, query)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  create(instance: models.Robot): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  retrieve(id: string): Promise<models.Robot> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  update(instance: models.Robot): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${this.URL}/${instance.id}`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  delete(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${this.URL}/${id}`)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}

@injectable()
export class RobotLocalGateway implements gateways.IRobotGateway {
  KEY: string = "robot";

  _load(): any {
    const raw = localStorage.getItem(this.KEY);
    if (raw != null) {
      return JSON.parse(raw);
    } else {
      return {};
    }
  }

  _save(data: any) {
    const raw = JSON.stringify(data);
    localStorage.setItem(this.KEY, raw);
  }

  _set(instance: models.Robot) {
    let data = this._load();
    data[instance.id] = instance;
    this._save(data);
  }

  list(): Promise<Array<models.Robot>> {
    return new Promise(resolve => {
      const data = this._load();
      let result = [];
      for (let id in data) {
        let instance = data[id];
        result.push(instance);
      }
      resolve(result);
    });
  }

  // TODO
  filter(query: any): Promise<Array<models.Robot>> {
    return this.list();
  }

  create(instance: models.Robot): Promise<void> {
    return new Promise(resolve => {
      this._set(instance);
      resolve();
    });
  }

  retrieve(id: string): Promise<models.Robot> {
    return new Promise(resolve => {
      const data = this._load();
      resolve(data[id]);
    });
  }

  update(instance: models.Robot): Promise<void> {
    return new Promise(resolve => {
      this._set(instance);
      resolve();
    });
  }

  delete(id: string): Promise<void> {
    return new Promise(resolve => {
      let data = this._load();
      delete data[id];
      this._save(data);
      resolve();
    });
  }
}

@injectable()
export class RobotSampleServerGateway implements gateways.IRobotSampleGateway {
  URL: string = `${constants.SERVER_ADDRESS}/robots/robot-sample`;

  list(): Promise<Array<models.RobotSample>> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  filter(query: any): Promise<Array<models.RobotSample>> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, query)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  create(instance: models.RobotSample): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  retrieve(id: string): Promise<models.RobotSample> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  update(instance: models.RobotSample): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${this.URL}/${instance.id}`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  delete(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${this.URL}/${id}`)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}

@injectable()
export class RobotSampleLocalGateway implements gateways.IRobotSampleGateway {
  KEY: string = "robot-sample";

  _load(): any {
    const raw = localStorage.getItem(this.KEY);
    if (raw != null) {
      return JSON.parse(raw);
    } else {
      return {};
    }
  }

  _save(data: any) {
    const raw = JSON.stringify(data);
    localStorage.setItem(this.KEY, raw);
  }

  _set(instance: models.RobotSample) {
    let data = this._load();
    data[instance.id] = instance;
    this._save(data);
  }

  list(): Promise<Array<models.RobotSample>> {
    return new Promise(resolve => {
      const data = this._load();
      let result = [];
      for (let id in data) {
        let instance = data[id];
        result.push(instance);
      }
      resolve(result);
    });
  }

  // TODO
  filter(query: any): Promise<Array<models.RobotSample>> {
    return this.list();
  }

  create(instance: models.RobotSample): Promise<void> {
    return new Promise(resolve => {
      this._set(instance);
      resolve();
    });
  }

  retrieve(id: string): Promise<models.RobotSample> {
    return new Promise(resolve => {
      const data = this._load();
      resolve(data[id]);
    });
  }

  update(instance: models.RobotSample): Promise<void> {
    return new Promise(resolve => {
      this._set(instance);
      resolve();
    });
  }

  delete(id: string): Promise<void> {
    return new Promise(resolve => {
      let data = this._load();
      delete data[id];
      this._save(data);
      resolve();
    });
  }
}

@injectable()
export class RobotTestServerGateway implements gateways.IRobotTestGateway {
  URL: string = `${constants.SERVER_ADDRESS}/robots/robot-test`;

  list(): Promise<Array<models.RobotTest>> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  filter(query: any): Promise<Array<models.RobotTest>> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, query)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  create(instance: models.RobotTest): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  retrieve(id: string): Promise<models.RobotTest> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  update(instance: models.RobotTest): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${this.URL}/${instance.id}`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  delete(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${this.URL}/${id}`)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}

@injectable()
export class RobotTestLocalGateway implements gateways.IRobotTestGateway {
  KEY: string = "robot-test";

  _load(): any {
    const raw = localStorage.getItem(this.KEY);
    if (raw != null) {
      return JSON.parse(raw);
    } else {
      return {};
    }
  }

  _save(data: any) {
    const raw = JSON.stringify(data);
    localStorage.setItem(this.KEY, raw);
  }

  _set(instance: models.RobotTest) {
    let data = this._load();
    data[instance.id] = instance;
    this._save(data);
  }

  list(): Promise<Array<models.RobotTest>> {
    return new Promise(resolve => {
      const data = this._load();
      let result = [];
      for (let id in data) {
        let instance = data[id];
        result.push(instance);
      }
      resolve(result);
    });
  }

  // TODO
  filter(query: any): Promise<Array<models.RobotTest>> {
    return this.list();
  }

  create(instance: models.RobotTest): Promise<void> {
    return new Promise(resolve => {
      this._set(instance);
      resolve();
    });
  }

  retrieve(id: string): Promise<models.RobotTest> {
    return new Promise(resolve => {
      const data = this._load();
      resolve(data[id]);
    });
  }

  update(instance: models.RobotTest): Promise<void> {
    return new Promise(resolve => {
      this._set(instance);
      resolve();
    });
  }

  delete(id: string): Promise<void> {
    return new Promise(resolve => {
      let data = this._load();
      delete data[id];
      this._save(data);
      resolve();
    });
  }
}
