import { injectable, inject } from "inversify";
import { RouterController } from "@/adapters/controllers/router";
// import WelcomeScreenController from "@/adapters/controllers/screen-welcome";

@injectable()
export default class SelectSignUpInteractor {
  // _welcomeScreenController: WelcomeScreenController;
  _routerController: RouterController;

  constructor(
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._routerController = routerController;
  }

  async handle(): Promise<void> {
    this._routerController.pushSignUpAge!();
  }
}
