import { injectable, inject } from "inversify";
import OverviewScreenController from "@/adapters/controllers/screen-overview";
import { RouterController } from "@/adapters/controllers/router";

@injectable()
export default class SelectAvailableReportInteractor {
  _overviewScreenController: OverviewScreenController;
  _routerController: RouterController;

  constructor(
    @inject(OverviewScreenController)
    overviewScreenController: OverviewScreenController,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._overviewScreenController = overviewScreenController;
    this._routerController = routerController;
  }

  async handle(reportId: string): Promise<void> {
    const screenData = this._overviewScreenController.getData!();
    let variantId: string | undefined = undefined;
    for (const variant of screenData.reportVariants) {
      if (variant.report && variant.report.id == reportId) {
        variantId = variant.id;
        break;
      }
    }
    if (variantId != undefined) {
      this._routerController.pushResultDetails!(variantId);
    } else {
      this._routerController.pushResultDetailsEmpty!(reportId);
    }
  }
}
