import { injectable } from "inversify";
import ResultDetailsScreenData from "@/adapters/models/screen-result-details";
import { Session } from "@/domain/users/models";

type EmptyAction = () => void;
type SetData = (data: ResultDetailsScreenData) => void;
type GetData = () => ResultDetailsScreenData;

@injectable()
export default class ResultDetailsScreenController {
  private cacheValidator?: string;
  private cache = new Map<string, ResultDetailsScreenData>();

  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;

  saveCache(id: string, userId: string) {
    if (userId) {
      this.cache.set(id, this.getData!());
      this.cacheValidator = userId;
    }
  }

  loadCache(id: string, userId: string): ResultDetailsScreenData | undefined {
    if (this.cacheValidator == userId) {
      return this.cache.get(id);
    } else {
      return undefined;
    }
  }
}
