import { injectable, inject } from "inversify";
import SignUpAgeScreenController from "@/adapters/controllers/screen-sign-up-age";
import Context from "@/domain/context";
import { RouterController } from "@/adapters/controllers/router";

@injectable()
export default class SelectContinueInteractor {
  _signUpAgeScreenController: SignUpAgeScreenController;
  _routerController: RouterController;

  constructor(
    @inject(SignUpAgeScreenController)
    signUpAgeScreenController: SignUpAgeScreenController,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._signUpAgeScreenController = signUpAgeScreenController;
    this._routerController = routerController;
  }

  async handle(context: Context, birthDate: Date): Promise<void> {
    context.birthDate = birthDate;
    this._routerController.pushSignUpUpload!();
  }
}
