import { injectable, inject } from "inversify";
import AccountScreenController from "@/adapters/controllers/screen-account";
import { IUserGateway, ISessionGateway } from "@/adapters/gateways/users";
import { UserPasswordUpdate } from "@/domain/users/models";

@injectable()
export default class SubmitPasswordUpdateInteractor {
  _accountScreenController: AccountScreenController;
  _userGateway: IUserGateway;
  _sessionGateway: ISessionGateway;

  constructor(
    @inject(AccountScreenController)
    accountScreenController: AccountScreenController,
    @inject("UserGateway")
    userGateway: IUserGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._accountScreenController = accountScreenController;
    this._userGateway = userGateway;
    this._sessionGateway = sessionGateway;
  }

  async handle(request: UserPasswordUpdate): Promise<void> {
    this._accountScreenController.resetFormErrors!();
    if (!request.isCurrentPasswordValid()) {
      this._accountScreenController.setAsCurrentPasswordInvalid!();
      return;
    }
    if (!request.isNewPasswordValid()) {
      this._accountScreenController.setAsNewPasswordInvalid!();
      return;
    }
    if (!request.isNewPasswordConfirmValid()) {
      this._accountScreenController.setAsNewPasswordConfirmInvalid!();
      return;
    }

    this._accountScreenController.setAsLoading!();
    let session = this._sessionGateway.get();
    request.protect();
    await this._userGateway.updatePassword(
      request.currentPassword,
      request.newPassword,
      session
    );
    session.credential = await this._userGateway.me(session);
    this._accountScreenController.setData!({
      session,
      credential: session.credential
    });
    this._sessionGateway.set(session);
    this._accountScreenController.setAsPasswordUpdateSuccess!();
    this._accountScreenController.setAsLoaded!();
  }
}
