import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";
import { inject, injectable } from "inversify";

@injectable()
export default class SelectLogoutInteractor {
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(): Promise<void> {
    const session = this._sessionGateway.get();
    session.user = undefined;
    session.tokens = undefined;
    session.credential = undefined;
    this._sessionGateway.set(session);
    this._routerController.openWellcome!();
  }
}
