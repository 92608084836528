import AccountScreenController from "@/adapters/controllers/screen-account";
import { ISessionGateway, IUserGateway } from "@/adapters/gateways/users";
import { Localization } from "@/commons/localization";
import { UserAccountUpdate } from "@/domain/users/models";
import { inject, injectable } from "inversify";

@injectable()
export default class SubmitAccountInteractor {
  _accountScreenController: AccountScreenController;
  _userGateway: IUserGateway;
  _sessionGateway: ISessionGateway;
  _localization: Localization;

  constructor(
    @inject(AccountScreenController)
    accountScreenController: AccountScreenController,
    @inject("UserGateway")
    userGateway: IUserGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject("Localization")
    localization: Localization
  ) {
    this._accountScreenController = accountScreenController;
    this._userGateway = userGateway;
    this._sessionGateway = sessionGateway;
    this._localization = localization;
  }

  async handle(request: UserAccountUpdate): Promise<void> {
    this._accountScreenController.resetFormErrors!();
    if (!request.isNameValid()) {
      this._accountScreenController.setAsNameInvalid!();
      return;
    }

    this._accountScreenController.setAsLoading!();
    let session = this._sessionGateway.get();
    await this._userGateway.updateAccount(
      request.name,
      request.language,
      session
    );
    session.credential = await this._userGateway.me(session);
    for (const entry of session.credential!.user.children!) {
      if (entry.child!.id == session.user!.id) {
        session.user = entry.child;
        break;
      }
    }
    this._sessionGateway.set(session);
    this._accountScreenController.setData!({
      session,
      credential: session.credential
    });
    this._localization.set(request.language);
    this._accountScreenController.setAsAccountUpdateSuccess!();
    this._accountScreenController.setAsLoaded!();
  }
}
