import { injectable, inject } from "inversify";
import SignUpAccountScreenController from "@/adapters/controllers/screen-sign-up-account";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";
import Context from "@/domain/context";

@injectable()
export default class InitSignUpAccountScreenInteractor {
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(context: Context): Promise<void> {
    if (!context.birthDate) {
      this._routerController.pushSignUpAge!();
      return;
    }
    let session = this._sessionGateway.get();
    if (session && session.user) {
      this._routerController.openOverview!();
      return;
    }
  }
}
