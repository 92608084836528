import { injectable } from "inversify";
import OverviewScreenData from "@/adapters/models/screen-overview";
import { Session } from "@/domain/users/models";

type EmptyAction = () => void;
type SetData = (data: OverviewScreenData) => void;
type GetData = () => OverviewScreenData;

@injectable()
export default class OverviewScreenController {
  private cacheValidator?: string;
  private cache?: OverviewScreenData;

  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;

  saveCache(userId: string) {
    if (userId) {
      this.cache = this.getData!();
      this.cacheValidator = userId;
    }
  }
  loadCache(userId: string): OverviewScreenData | undefined {
    if (this.cacheValidator == userId) {
      return this.cache;
    } else {
      return undefined;
    }
  }
}
