import { injectable, inject } from "inversify";
// import LoginScreenController from "@/adapters/controllers/screen-login";
import { RouterController } from "@/adapters/controllers/router";

@injectable()
export default class SelectSignUpInteractor {
  // _loginScreenController: LoginScreenController;
  _routerController: RouterController;

  constructor(
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._routerController = routerController;
  }

  async handle(): Promise<void> {
    this._routerController.pushSignUpUpload!();
  }
}
