import axios from "axios";
import { injectable } from "inversify";

import * as models from "@/domain/correlations/models";
import * as gateways from "@/adapters/gateways/correlations";
import * as constants from "@/commons/constants";
import { Tokens } from "@/domain/users/models";

@injectable()
export class CorrelationServerGateway implements gateways.ICorrelationGateway {
  URL: string = `${constants.SERVER_ADDRESS}/correlations/correlation`;

  myResults(
    tokens: Tokens,
    correlationId: string
  ): Promise<models.CorrelationUserReport> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/my-results/?correlation=${correlationId}`, {
          headers: tokens.getHeader()
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  list(): Promise<Array<models.Correlation>> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  filter(query: any): Promise<Array<models.Correlation>> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, query)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  create(instance: models.Correlation): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  retrieve(id: string): Promise<models.Correlation> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  update(instance: models.Correlation): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${this.URL}/${instance.id}`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  delete(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${this.URL}/${id}`)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}

@injectable()
export class CorrelationGroupServerGateway
  implements gateways.ICorrelationGroupGateway {
  URL: string = `${constants.SERVER_ADDRESS}/correlations/correlation-group`;

  list(): Promise<Array<models.CorrelationGroup>> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  filter(query: any): Promise<Array<models.CorrelationGroup>> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, query)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  create(instance: models.CorrelationGroup): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  retrieve(id: string): Promise<models.CorrelationGroup> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  update(instance: models.CorrelationGroup): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${this.URL}/${instance.id}`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  delete(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${this.URL}/${id}`)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}

@injectable()
export class CorrelationGroupLocalGateway
  implements gateways.ICorrelationGroupGateway {
  KEY: string = "correlation-group";

  _load(): any {
    const raw = localStorage.getItem(this.KEY);
    if (raw != null) {
      return JSON.parse(raw);
    } else {
      return {};
    }
  }

  _save(data: any) {
    const raw = JSON.stringify(data);
    localStorage.setItem(this.KEY, raw);
  }

  _set(instance: models.CorrelationGroup) {
    let data = this._load();
    data[instance.id] = instance;
    this._save(data);
  }

  list(): Promise<Array<models.CorrelationGroup>> {
    return new Promise(resolve => {
      const data = this._load();
      let result = [];
      for (let id in data) {
        let instance = data[id];
        result.push(instance);
      }
      resolve(result);
    });
  }

  // TODO
  filter(query: any): Promise<Array<models.CorrelationGroup>> {
    return this.list();
  }

  create(instance: models.CorrelationGroup): Promise<void> {
    return new Promise(resolve => {
      this._set(instance);
      resolve();
    });
  }

  retrieve(id: string): Promise<models.CorrelationGroup> {
    return new Promise(resolve => {
      const data = this._load();
      resolve(data[id]);
    });
  }

  update(instance: models.CorrelationGroup): Promise<void> {
    return new Promise(resolve => {
      this._set(instance);
      resolve();
    });
  }

  delete(id: string): Promise<void> {
    return new Promise(resolve => {
      let data = this._load();
      delete data[id];
      this._save(data);
      resolve();
    });
  }
}

@injectable()
export class CorrelationGroupUserServerGateway
  implements gateways.ICorrelationGroupUserGateway {
  URL: string = `${constants.SERVER_ADDRESS}/correlations/correlation-group-user`;

  list(): Promise<Array<models.CorrelationGroupUser>> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  filter(query: any): Promise<Array<models.CorrelationGroupUser>> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, query)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  create(instance: models.CorrelationGroupUser): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  retrieve(id: string): Promise<models.CorrelationGroupUser> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  update(instance: models.CorrelationGroupUser): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${this.URL}/${instance.id}`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  delete(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${this.URL}/${id}`)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}

@injectable()
export class CorrelationGroupUserLocalGateway
  implements gateways.ICorrelationGroupUserGateway {
  KEY: string = "correlation-group-user";

  _load(): any {
    const raw = localStorage.getItem(this.KEY);
    if (raw != null) {
      return JSON.parse(raw);
    } else {
      return {};
    }
  }

  _save(data: any) {
    const raw = JSON.stringify(data);
    localStorage.setItem(this.KEY, raw);
  }

  _set(instance: models.CorrelationGroupUser) {
    let data = this._load();
    data[instance.id] = instance;
    this._save(data);
  }

  list(): Promise<Array<models.CorrelationGroupUser>> {
    return new Promise(resolve => {
      const data = this._load();
      let result = [];
      for (let id in data) {
        let instance = data[id];
        result.push(instance);
      }
      resolve(result);
    });
  }

  // TODO
  filter(query: any): Promise<Array<models.CorrelationGroupUser>> {
    return this.list();
  }

  create(instance: models.CorrelationGroupUser): Promise<void> {
    return new Promise(resolve => {
      this._set(instance);
      resolve();
    });
  }

  retrieve(id: string): Promise<models.CorrelationGroupUser> {
    return new Promise(resolve => {
      const data = this._load();
      resolve(data[id]);
    });
  }

  update(instance: models.CorrelationGroupUser): Promise<void> {
    return new Promise(resolve => {
      this._set(instance);
      resolve();
    });
  }

  delete(id: string): Promise<void> {
    return new Promise(resolve => {
      let data = this._load();
      delete data[id];
      this._save(data);
      resolve();
    });
  }
}

@injectable()
export class CorrelationControlResultsServerGateway
  implements gateways.ICorrelationControlResultsGateway {
  URL: string = `${constants.SERVER_ADDRESS}/correlations/correlation-control-results`;

  list(): Promise<Array<models.CorrelationControlResults>> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  filter(query: any): Promise<Array<models.CorrelationControlResults>> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, query)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  create(instance: models.CorrelationControlResults): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  retrieve(id: string): Promise<models.CorrelationControlResults> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  update(instance: models.CorrelationControlResults): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${this.URL}/${instance.id}`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  delete(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${this.URL}/${id}`)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}

@injectable()
export class CorrelationControlResultsLocalGateway
  implements gateways.ICorrelationControlResultsGateway {
  KEY: string = "correlation-control-results";

  _load(): any {
    const raw = localStorage.getItem(this.KEY);
    if (raw != null) {
      return JSON.parse(raw);
    } else {
      return {};
    }
  }

  _save(data: any) {
    const raw = JSON.stringify(data);
    localStorage.setItem(this.KEY, raw);
  }

  _set(instance: models.CorrelationControlResults) {
    let data = this._load();
    data[instance.id] = instance;
    this._save(data);
  }

  list(): Promise<Array<models.CorrelationControlResults>> {
    return new Promise(resolve => {
      const data = this._load();
      let result = [];
      for (let id in data) {
        let instance = data[id];
        result.push(instance);
      }
      resolve(result);
    });
  }

  // TODO
  filter(query: any): Promise<Array<models.CorrelationControlResults>> {
    return this.list();
  }

  create(instance: models.CorrelationControlResults): Promise<void> {
    return new Promise(resolve => {
      this._set(instance);
      resolve();
    });
  }

  retrieve(id: string): Promise<models.CorrelationControlResults> {
    return new Promise(resolve => {
      const data = this._load();
      resolve(data[id]);
    });
  }

  update(instance: models.CorrelationControlResults): Promise<void> {
    return new Promise(resolve => {
      this._set(instance);
      resolve();
    });
  }

  delete(id: string): Promise<void> {
    return new Promise(resolve => {
      let data = this._load();
      delete data[id];
      this._save(data);
      resolve();
    });
  }
}

@injectable()
export class CorrelationGroupResultsServerGateway
  implements gateways.ICorrelationGroupResultsGateway {
  URL: string = `${constants.SERVER_ADDRESS}/correlations/correlation-group-results`;

  list(): Promise<Array<models.CorrelationGroupResults>> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  filter(query: any): Promise<Array<models.CorrelationGroupResults>> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, query)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  create(instance: models.CorrelationGroupResults): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  retrieve(id: string): Promise<models.CorrelationGroupResults> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  update(instance: models.CorrelationGroupResults): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${this.URL}/${instance.id}`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  delete(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${this.URL}/${id}`)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}

@injectable()
export class CorrelationGroupResultsLocalGateway
  implements gateways.ICorrelationGroupResultsGateway {
  KEY: string = "correlation-group-results";

  _load(): any {
    const raw = localStorage.getItem(this.KEY);
    if (raw != null) {
      return JSON.parse(raw);
    } else {
      return {};
    }
  }

  _save(data: any) {
    const raw = JSON.stringify(data);
    localStorage.setItem(this.KEY, raw);
  }

  _set(instance: models.CorrelationGroupResults) {
    let data = this._load();
    data[instance.id] = instance;
    this._save(data);
  }

  list(): Promise<Array<models.CorrelationGroupResults>> {
    return new Promise(resolve => {
      const data = this._load();
      let result = [];
      for (let id in data) {
        let instance = data[id];
        result.push(instance);
      }
      resolve(result);
    });
  }

  // TODO
  filter(query: any): Promise<Array<models.CorrelationGroupResults>> {
    return this.list();
  }

  create(instance: models.CorrelationGroupResults): Promise<void> {
    return new Promise(resolve => {
      this._set(instance);
      resolve();
    });
  }

  retrieve(id: string): Promise<models.CorrelationGroupResults> {
    return new Promise(resolve => {
      const data = this._load();
      resolve(data[id]);
    });
  }

  update(instance: models.CorrelationGroupResults): Promise<void> {
    return new Promise(resolve => {
      this._set(instance);
      resolve();
    });
  }

  delete(id: string): Promise<void> {
    return new Promise(resolve => {
      let data = this._load();
      delete data[id];
      this._save(data);
      resolve();
    });
  }
}

@injectable()
export class CorrelationUserReportServerGateway
  implements gateways.ICorrelationUserReportGateway {
  URL: string = `${constants.SERVER_ADDRESS}/correlations/correlation-user-report`;

  list(): Promise<Array<models.CorrelationUserReport>> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  filter(query: any): Promise<Array<models.CorrelationUserReport>> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, query)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  create(instance: models.CorrelationUserReport): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  retrieve(id: string): Promise<models.CorrelationUserReport> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  update(instance: models.CorrelationUserReport): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${this.URL}/${instance.id}`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  delete(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${this.URL}/${id}`)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}

@injectable()
export class CorrelationUserReportLocalGateway
  implements gateways.ICorrelationUserReportGateway {
  KEY: string = "correlation-user-report";

  _load(): any {
    const raw = localStorage.getItem(this.KEY);
    if (raw != null) {
      return JSON.parse(raw);
    } else {
      return {};
    }
  }

  _save(data: any) {
    const raw = JSON.stringify(data);
    localStorage.setItem(this.KEY, raw);
  }

  _set(instance: models.CorrelationUserReport) {
    let data = this._load();
    data[instance.id] = instance;
    this._save(data);
  }

  list(): Promise<Array<models.CorrelationUserReport>> {
    return new Promise(resolve => {
      const data = this._load();
      let result = [];
      for (let id in data) {
        let instance = data[id];
        result.push(instance);
      }
      resolve(result);
    });
  }

  // TODO
  filter(query: any): Promise<Array<models.CorrelationUserReport>> {
    return this.list();
  }

  create(instance: models.CorrelationUserReport): Promise<void> {
    return new Promise(resolve => {
      this._set(instance);
      resolve();
    });
  }

  retrieve(id: string): Promise<models.CorrelationUserReport> {
    return new Promise(resolve => {
      const data = this._load();
      resolve(data[id]);
    });
  }

  update(instance: models.CorrelationUserReport): Promise<void> {
    return new Promise(resolve => {
      this._set(instance);
      resolve();
    });
  }

  delete(id: string): Promise<void> {
    return new Promise(resolve => {
      let data = this._load();
      delete data[id];
      this._save(data);
      resolve();
    });
  }
}

@injectable()
export class CorrelationUserReportResultsServerGateway
  implements gateways.ICorrelationUserReportResultsGateway {
  URL: string = `${constants.SERVER_ADDRESS}/correlations/correlation-user-report-results`;

  list(): Promise<Array<models.CorrelationUserReportResults>> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  filter(query: any): Promise<Array<models.CorrelationUserReportResults>> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, query)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  create(instance: models.CorrelationUserReportResults): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  retrieve(id: string): Promise<models.CorrelationUserReportResults> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  update(instance: models.CorrelationUserReportResults): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${this.URL}/${instance.id}`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  delete(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${this.URL}/${id}`)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}

@injectable()
export class CorrelationUserReportResultsLocalGateway
  implements gateways.ICorrelationUserReportResultsGateway {
  KEY: string = "correlation-user-report-results";

  _load(): any {
    const raw = localStorage.getItem(this.KEY);
    if (raw != null) {
      return JSON.parse(raw);
    } else {
      return {};
    }
  }

  _save(data: any) {
    const raw = JSON.stringify(data);
    localStorage.setItem(this.KEY, raw);
  }

  _set(instance: models.CorrelationUserReportResults) {
    let data = this._load();
    data[instance.id] = instance;
    this._save(data);
  }

  list(): Promise<Array<models.CorrelationUserReportResults>> {
    return new Promise(resolve => {
      const data = this._load();
      let result = [];
      for (let id in data) {
        let instance = data[id];
        result.push(instance);
      }
      resolve(result);
    });
  }

  // TODO
  filter(query: any): Promise<Array<models.CorrelationUserReportResults>> {
    return this.list();
  }

  create(instance: models.CorrelationUserReportResults): Promise<void> {
    return new Promise(resolve => {
      this._set(instance);
      resolve();
    });
  }

  retrieve(id: string): Promise<models.CorrelationUserReportResults> {
    return new Promise(resolve => {
      const data = this._load();
      resolve(data[id]);
    });
  }

  update(instance: models.CorrelationUserReportResults): Promise<void> {
    return new Promise(resolve => {
      this._set(instance);
      resolve();
    });
  }

  delete(id: string): Promise<void> {
    return new Promise(resolve => {
      let data = this._load();
      delete data[id];
      this._save(data);
      resolve();
    });
  }
}
