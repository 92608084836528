import { injectable } from "inversify";
import PasswordResetScreenData from "@/adapters/models/screen-password-reset";

type EmptyAction = () => void;
type SetData = (data: PasswordResetScreenData) => void;
type GetData = () => PasswordResetScreenData;

@injectable()
export default class PasswordResetApplyScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
  setAsPasswordInvalid?: EmptyAction;
  setAsConfirmPasswordInvalid?: EmptyAction;
  clearFormErrors?: EmptyAction;
  setAsSuccess?: EmptyAction;
}
