import { injectable, inject } from "inversify";
import { IUserGateway, ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";

@injectable()
export default class CreateUserChildrenInteractor {
  _userGateway: IUserGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject("UserGateway")
    userGateway: IUserGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._userGateway = userGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(name: string): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.user) {
      this._routerController.openWellcome!();
      return;
    }
    const childId = await this._userGateway.addChildren(name, session);
    session.credential = await this._userGateway.me(session);
    for (const entry of session.credential!.user.children!) {
      if (entry.child!.id == childId) {
        session.user = entry.child;
        break;
      }
    }
    this._sessionGateway.set(session);
    setTimeout(() => this._routerController.openLogin!(), 250);
  }
}
