import { injectable, inject } from "inversify";
import ResultDetailsEmptyScreenController from "@/adapters/controllers/screen-result-details-empty";
import { IReportGateway } from "@/adapters/gateways/reports";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";
import { Session } from "@/domain/users/models";

@injectable()
export default class InitResultDetailsEmptyScreenInteractor {
  _resultDetailsEmptyScreenController: ResultDetailsEmptyScreenController;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;
  _reportsGateway: IReportGateway;

  constructor(
    @inject(ResultDetailsEmptyScreenController)
    resultDetailsEmptyScreenController: ResultDetailsEmptyScreenController,
    @inject("ReportGateway")
    reportsGateway: IReportGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._resultDetailsEmptyScreenController = resultDetailsEmptyScreenController;
    this._reportsGateway = reportsGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(id: string): Promise<void> {
    this._resultDetailsEmptyScreenController.setAsLoading!();
    const session = this._sessionGateway.get();
    if (!session || !session.user) {
      this._routerController.openWellcome!();
      return;
    }

    const userId = session.user!.id;
    const cachedData = this._resultDetailsEmptyScreenController.loadCache(
      id,
      userId
    );
    if (cachedData) {
      this._resultDetailsEmptyScreenController.setData!(cachedData);
    } else {
      const report = await this._reportsGateway.details(id, session);
      this._resultDetailsEmptyScreenController.setData!({
        report,
        session
      });
      this._resultDetailsEmptyScreenController.saveCache(id, userId);
    }

    this._resultDetailsEmptyScreenController.setAsLoaded!();
  }
}
