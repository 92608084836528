import { injectable, inject } from "inversify";
import LoginScreenController from "@/adapters/controllers/screen-login";
import { UserSignIn, Session } from "@/domain/users/models";
import { IUserGateway, ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";
import { Localization } from "@/commons/localization";
import { IPlanGateway } from "@/adapters/gateways/plans";

@injectable()
export default class SubmitLoginInteractor {
  _loginScreenController: LoginScreenController;
  _userGateway: IUserGateway;
  _planGateway: IPlanGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;
  _localization: Localization;

  constructor(
    @inject(LoginScreenController)
    loginScreenController: LoginScreenController,
    @inject("UserGateway")
    userGateway: IUserGateway,
    @inject("PlanGateway")
    planGateway: IPlanGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController,
    @inject("Localization")
    localization: Localization
  ) {
    this._loginScreenController = loginScreenController;
    this._userGateway = userGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
    this._localization = localization;
    this._planGateway = planGateway;
  }

  async handle(userSignIn: UserSignIn): Promise<void> {
    this._loginScreenController.clearFormErrors!();
    let isValid = true;

    if (!userSignIn.isUsernameValid()) {
      this._loginScreenController.setAsUsernameInvalid!();
      isValid = false;
    }

    if (!userSignIn.isPasswordValid()) {
      this._loginScreenController.setAsPasswordInvalid!();
      isValid = false;
    }

    if (!isValid) return;

    this._loginScreenController.setAsLoading!();
    try {
      userSignIn.protect();
      const tokens = await this._userGateway.signIn(userSignIn);
      const session: Session = { tokens };
      session.credential = await this._userGateway.me(session);
      session.user = session.credential.user;

      this._sessionGateway.set(session);
      this._localization.set(session.user.language);
      this._routerController.pushOverview!();
    } catch (error) {
      if (error && error.response && error.response.status == 401) {
        this._loginScreenController.setAsUsernameAndPasswordInvalid!();
      }
      this._loginScreenController.setAsLoaded!();
    }
  }
}
