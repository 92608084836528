import { v4 as uuid } from "uuid";

export class Entity {
  id: string;
  createdAt: Date;
  modifiedAt?: Date;
  disabledAt?: Date;

  constructor(
    id?: string,
    createdAt?: Date,
    modifiedAt?: Date,
    disabledAt?: Date
  ) {
    this.id = id || uuid();
    this.createdAt = createdAt || new Date();
    this.modifiedAt = modifiedAt;
    this.disabledAt = disabledAt;
  }

  get isEnabled(): boolean {
    return this.disabledAt == null;
  }

  get isDisabled(): boolean {
    return this.disabledAt != null;
  }
}
