import * as gateways from "@/adapters/gateways/plans";
import * as constants from "@/commons/constants";
import * as models from "@/domain/plans/models";
import { Session, buildParams } from "@/domain/users/models";
import axios from "axios";
import { injectable } from "inversify";

@injectable()
export class PlanServerGateway implements gateways.IPlanGateway {
  URL: string = `${constants.SERVER_ADDRESS}/plans/plan`;

  planCodeActivate(code: string, session: Session): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${this.URL}/activate/`,
          { code },
          { params: buildParams(session), headers: session.tokens!.getHeader() }
        )
        .then(response => {
          resolve(response.data.isSuccess);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  my(session: Session): Promise<models.UserPlan> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/my`, {
          params: buildParams(session),
          headers: session.tokens!.getHeader()
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  upgradeToRawData(session: Session): Promise<models.UserPlan> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/raw-data-upgrade`, {
          params: buildParams(session),
          headers: session.tokens!.getHeader()
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  list(): Promise<Array<models.Plan>> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  filter(query: any): Promise<Array<models.Plan>> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, query)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  create(instance: models.Plan): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  retrieve(id: string): Promise<models.Plan> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  update(instance: models.Plan): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${this.URL}/${instance.id}`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  delete(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${this.URL}/${id}`)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
