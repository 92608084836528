import { injectable } from "inversify";
import SignUpAccountScreenData from "@/adapters/models/screen-sign-up-account";

type EmptyAction = () => void;
type SetData = (data: SignUpAccountScreenData) => void;
type GetData = () => SignUpAccountScreenData;

@injectable()
export default class SignUpAccountScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
  resetFormErrors?: EmptyAction;
  setAsNameInvalid?: EmptyAction;
  setAsEmailInvalid?: EmptyAction;
  setAsEmailAlreadyInUse?: EmptyAction;
  setAsPasswordInvalid?: EmptyAction;
  setAsConfirmPasswordInvalid?: EmptyAction;
}
