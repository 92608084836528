import { injectable, inject } from "inversify";
import AccountScreenController from "@/adapters/controllers/screen-account";
import { IUserGateway, ISessionGateway } from "@/adapters/gateways/users";
import { IPlanGateway } from "@/adapters/gateways/plans";
import { RouterController } from "@/adapters/controllers/router";
import { Localization } from "@/commons/localization";

@injectable()
export default class InitAccountInteractor {
  _accountScreenController: AccountScreenController;
  _userGateway: IUserGateway;
  _sessionGateway: ISessionGateway;
  _localization: Localization;
  _planGateway: IPlanGateway;
  _routerController: RouterController;

  constructor(
    @inject(AccountScreenController)
    accountScreenController: AccountScreenController,
    @inject("UserGateway")
    userGateway: IUserGateway,
    @inject("PlanGateway")
    planGateway: IPlanGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController,
    @inject("Localization")
    localization: Localization
  ) {
    this._accountScreenController = accountScreenController;
    this._userGateway = userGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
    this._localization = localization;
    this._planGateway = planGateway;
  }

  async handle(): Promise<void> {
    this._accountScreenController.setAsLoading!();
    let session = this._sessionGateway.get();
    if (!session || !session.user) {
      this._routerController.openWellcome!();
      return;
    }

    session.credential = await this._userGateway.me(session);
    session.user.plan = await this._planGateway.my(session);
    this._sessionGateway.set(session);

    this._accountScreenController.setData!({
      session,
      credential: session.credential
    });

    this._accountScreenController.setAsLoaded!();
  }
}
