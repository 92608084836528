<template>
  <div id="app" :class="vueAppClasses">
    <router-view @setAppClasses="setAppClasses" />
  </div>
</template>

<script>
import themeConfig from "@/../themeConfig.js";
import { RouterController } from "@/adapters/controllers/router";
export default {
  name: "app",
  data() {
    return {
      vueAppClasses: [],
      routerController: null
    };
  },
  beforeMount() {
    this.routerController = this.$injector.get(RouterController);

    //{ ROUTER
    const r = this.routerController;
    r.pop = () => this.$router.pop();
    r.push = (name, params) => this.$router.push({ name, params });
    r.open = (name, params) => this.$router.replace({ name, params });
    r.reload = () => this.$router.go();
    r.pushWellcome = () => r.push("Welcome");
    r.openWellcome = () => r.open("Welcome");
    r.pushLogin = () => r.push("Login");
    r.openLogin = () => r.open("Login");
    r.pushSignUpUpload = () => r.push("SignUpUpload");
    r.openSignUpUpload = () => r.open("SignUpUpload");
    r.pushSignUpAccount = id => r.push("SignUpAccount", { id });
    r.openSignUpAccount = id => r.open("SignUpAccount", { id });
    r.pushOverview = () => r.push("Overview");
    r.openOverview = () => r.open("Overview");
    r.pushResultDetails = id => r.push("ResultDetails", { id });
    r.openResultDetails = id => r.open("ResultDetails", { id });
    r.pushPasswordResetRequest = () => r.push("PasswordResetRequest");
    r.openPasswordResetRequest = () => r.open("PasswordResetRequest");
    r.pushPasswordReset = () => r.push("PasswordReset");
    r.openPasswordReset = () => r.open("PasswordReset");
    r.pushAccount = () => r.push("Account");
    r.openAccount = () => r.open("Account");
    r.pushResultDetailsEmpty = id => r.push("ResultDetailsEmpty", { id });
    r.openResultDetailsEmpty = id => r.open("ResultDetailsEmpty", { id });
    r.pushSignUpAge = () => r.push("SignUpAge");
    r.openSignUpAge = () => r.open("SignUpAge");
    r.pushPlanCodeActivate = () => r.push("PlanCodeActivate");
    r.openPlanCodeActivate = () => r.open("PlanCodeActivate");
    r.pushResearches = () => r.push("Researches");
    r.openResearches = () => r.open("Researches");
    //} ROUTER
  },
  watch: {
    "$store.state.theme"(val) {
      this.toggleClassInBody(val);
    },
    "$vs.rtl"(val) {
      document.documentElement.setAttribute("dir", val ? "rtl" : "ltr");
    }
  },
  methods: {
    toggleClassInBody(className) {
      if (className == "dark") {
        if (document.body.className.match("theme-semi-dark"))
          document.body.classList.remove("theme-semi-dark");
        document.body.classList.add("theme-dark");
      } else if (className == "semi-dark") {
        if (document.body.className.match("theme-dark"))
          document.body.classList.remove("theme-dark");
        document.body.classList.add("theme-semi-dark");
      } else {
        if (document.body.className.match("theme-dark"))
          document.body.classList.remove("theme-dark");
        if (document.body.className.match("theme-semi-dark"))
          document.body.classList.remove("theme-semi-dark");
      }
    },
    setAppClasses(classesStr) {
      this.vueAppClasses.push(classesStr);
    },
    handleWindowResize() {
      this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth);

      // Set --vh property
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.01}px`
      );
    },
    handleScroll() {
      this.$store.commit("UPDATE_WINDOW_SCROLL_Y", window.scrollY);
    }
  },
  mounted() {
    this.toggleClassInBody(themeConfig.theme);
    this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth);

    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  },
  async created() {
    let dir = this.$vs.rtl ? "rtl" : "ltr";
    document.documentElement.setAttribute("dir", dir);

    window.addEventListener("resize", this.handleWindowResize);
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleWindowResize);
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>

<style lang="scss">
.page {
  position: fixed;
  width: inherit;
}
.page-card {
  padding: 25px !important;
}

.centerx {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-wrap: wrap;
}

.centerx li {
  margin: 5px;
}
</style>
