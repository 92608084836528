import { injectable, inject } from "inversify";
import PasswordResetRequestScreenController from "@/adapters/controllers/screen-password-reset-request";
import { UserPasswordResetRequest } from "@/domain/users/models";
import { IUserGateway } from "@/adapters/gateways/users";

@injectable()
export default class SubmitPasswordResetRequestInteractor {
  _passwordResetRequestScreenController: PasswordResetRequestScreenController;
  _userGateway: IUserGateway;

  constructor(
    @inject(PasswordResetRequestScreenController)
    passwordResetRequestScreenController: PasswordResetRequestScreenController,
    @inject("UserGateway")
    userGateway: IUserGateway
  ) {
    this._passwordResetRequestScreenController = passwordResetRequestScreenController;
    this._userGateway = userGateway;
  }

  async handle(request: UserPasswordResetRequest): Promise<void> {
    this._passwordResetRequestScreenController.clearFormErrors!();
    if (!request.isEmailValid()) {
      this._passwordResetRequestScreenController.setAsEmailInvalid!();
      return;
    }

    this._passwordResetRequestScreenController.setAsLoading!();
    await this._userGateway.passwordResetRequest(request.email);
    this._passwordResetRequestScreenController.setAsLoaded!();
    this._passwordResetRequestScreenController.setAsSuccess!();
  }
}
