import { Localization } from "@/commons/localization";
import { inject, injectable } from "inversify";

@injectable()
export default class SelectLanguageInteractor {
  _localization: Localization;
  constructor(
    @inject("Localization")
    localization: Localization
  ) {
    this._localization = localization;
  }

  handle(language: string): void {
    this._localization.set(language);
  }
}
