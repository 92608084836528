import { injectable, inject } from "inversify";
import OverviewScreenController from "@/adapters/controllers/screen-overview";
import { ISessionGateway } from "@/adapters/gateways/users";
import { IReportGateway } from "@/adapters/gateways/reports";

@injectable()
export default class SelectDownloadFullReportInteractor {
  _overviewScreenController: OverviewScreenController;
  _sessionGateway: ISessionGateway;
  _reportGateway: IReportGateway;

  constructor(
    @inject(OverviewScreenController)
    overviewScreenController: OverviewScreenController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject("ReportGateway")
    reportGateway: IReportGateway
  ) {
    this._overviewScreenController = overviewScreenController;
    this._sessionGateway = sessionGateway;
    this._reportGateway = reportGateway;
  }

  async handle(): Promise<void> {
    this._overviewScreenController.setAsLoading!();
    const session = this._sessionGateway.get();
    const response = await this._reportGateway.build(session);
    const url = this._reportGateway.downloadUrl(response.code);
    this._overviewScreenController.setAsLoaded!();
    const data = this._overviewScreenController.getData!();
    data.isWaitingDownload = true;
    this._overviewScreenController.setData!(data);
    window.location.assign(url);
  }
}
