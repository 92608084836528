import { injectable, inject } from "inversify";
import PlanCodeActivateScreenController from "@/adapters/controllers/screen-plan-code-activate";
import { IPlanGateway } from "@/adapters/gateways/plans";
import { ISessionGateway } from "@/adapters/gateways/users";

@injectable()
export default class SubmitPlanCodeInteractor {
  _planCodeActivateScreenController: PlanCodeActivateScreenController;
  _planGateway: IPlanGateway;
  _sessionGateway: ISessionGateway;

  constructor(
    @inject(PlanCodeActivateScreenController)
    planCodeActivateScreenController: PlanCodeActivateScreenController,
    @inject("PlanGateway")
    planGateway: IPlanGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._planCodeActivateScreenController = planCodeActivateScreenController;
    this._planGateway = planGateway;
    this._sessionGateway = sessionGateway;
  }

  async handle(code: string): Promise<void> {
    this._planCodeActivateScreenController.setAsLoading!();
    const session = this._sessionGateway.get();
    const isSuccess = await this._planGateway.planCodeActivate(code, session);
    if (!isSuccess) {
      this._planCodeActivateScreenController.setAsCodeNotFound!();
    } else {
      session.user!.plan = await this._planGateway.my(session);
      this._sessionGateway.set(session);
      this._planCodeActivateScreenController.setData!({ session });
    }
    this._planCodeActivateScreenController.setAsLoaded!();
  }
}
