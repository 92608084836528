import { injectable, inject } from "inversify";
import AccountScreenController from "@/adapters/controllers/screen-account";

@injectable()
export default class SelectAccountDeleteInteractor {
  _accountScreenController: AccountScreenController;

  constructor(
    @inject(AccountScreenController)
    accountScreenController: AccountScreenController
  ) {
    this._accountScreenController = accountScreenController;
  }

  async handle(): Promise<void> {}
}
