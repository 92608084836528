import { injectable } from "inversify";
import SignUpUploadScreenData from "@/adapters/models/screen-sign-up-upload";

type EmptyAction = () => void;
type SetData = (data: SignUpUploadScreenData) => void;
type GetData = () => SignUpUploadScreenData;

@injectable()
export default class SignUpUploadScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
