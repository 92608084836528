import { RouterController } from "@/adapters/controllers/router";
import OverviewScreenController from "@/adapters/controllers/screen-overview";
import { IReportVariantGateway } from "@/adapters/gateways/reports";
import { ISessionGateway, IUserGateway } from "@/adapters/gateways/users";
import OverviewScreenData from "@/adapters/models/screen-overview";
import { inject, injectable } from "inversify";
import { ICorrelationGateway } from "@/adapters/gateways/correlations";

@injectable()
export default class InitOverviewScreenInteractor {
  _overviewScreenController: OverviewScreenController;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;
  _reportVariantGateway: IReportVariantGateway;
  _userGateway: IUserGateway;
  _correlationGateway: ICorrelationGateway;

  _timer: any;

  constructor(
    @inject(OverviewScreenController)
    overviewScreenController: OverviewScreenController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController,
    @inject("ReportVariantGateway")
    reportVariantGateway: IReportVariantGateway,
    @inject("UserGateway")
    userGateway: IUserGateway,
    @inject("CorrelationGateway")
    correlationGateway: ICorrelationGateway
  ) {
    this._overviewScreenController = overviewScreenController;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
    this._reportVariantGateway = reportVariantGateway;
    this._userGateway = userGateway;
    this._correlationGateway = correlationGateway;
  }

  async handle(ignoreCache: boolean = false): Promise<void> {
    clearTimeout(this._timer);
    this._overviewScreenController.setAsLoading!();
    const session = this._sessionGateway.get();
    if (!session || !session.user) {
      this._routerController.openWellcome!();
      return;
    }
    const userId = session.user!.id;
    const isPlatinum = session.user!.plan!.plan.isPlatinum;
    let cachedData: OverviewScreenData | undefined;
    if (!ignoreCache) {
      cachedData = this._overviewScreenController.loadCache(userId);
    }

    if (cachedData) {
      this._overviewScreenController.setData!(cachedData);
      this._overviewScreenController.setAsLoaded!();
    } else {
      let check = await this._userGateway.dashboardCheck(session);

      if (!check.isRawDataProcessed) {
        const _ = this;
        this._timer = setTimeout(() => {
          _.handle(ignoreCache);
        }, 5000);
        this._overviewScreenController.setData!({
          reportVariants: [],
          session,
          check,
          isWaitingDownload: false,
          isRawDataFailed: false,
          customDna: []
        });
        this._overviewScreenController.setAsLoaded!();
        return;
      }

      let reportVariantsRequest = this._reportVariantGateway.my(session);
      let dashboardRequest = this._userGateway.dashboardData(session);
      let customDnaRequest = this._userGateway.findCustomDna(
        [
          "664fe831-bce4-4a66-a343-6f70724fdcc7",
          "928c8c51-b8a8-4b29-aae4-51a1d04a38a7",
          "7b314a02-10a9-4729-891b-281dcd7ae6b6",
          "93d0d99b-9430-4a7d-b34b-7540f2cf7291",
          "344a8117-8c07-4fc3-a13f-de263fbe1188",
          "32d1b55d-a9f4-447b-8695-57fee7d102b8",
          "a2e9e1b0-9f13-4e9c-969f-e16bf5580d14",
          "0335eae1-3965-46b6-a1d2-b38b9feaf272"
        ],
        session
      );
      let reportVariants = await reportVariantsRequest;
      let dashboard = await dashboardRequest;
      let customDna = await customDnaRequest;
      let overviewScreenData: OverviewScreenData = {
        reportVariants,
        session,
        dashboard,
        check,
        customDna,
        isWaitingDownload: false,
        isRawDataFailed: reportVariants.length == 0
      };
      if (isPlatinum) {
        overviewScreenData.soccerResults = await this._correlationGateway.myResults(
          session.tokens!,
          "38c2f7e6-0432-4cd7-bc64-b12fdf480bb5"
        );
      }
      this._overviewScreenController.setData!(overviewScreenData);
      this._overviewScreenController.saveCache(userId);
      this._overviewScreenController.setAsLoaded!();
    }
  }
}
