import { injectable } from "inversify";
import PlanCodeActivateScreenData from "@/adapters/models/screen-plan-code-activate";

type EmptyAction = () => void;
type SetData = (data: PlanCodeActivateScreenData) => void;
type GetData = () => PlanCodeActivateScreenData;

@injectable()
export default class PlanCodeActivateScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
  setAsCodeNotFound?: EmptyAction;
}
