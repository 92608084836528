import { injectable, inject } from "inversify";
import { RouterController } from "@/adapters/controllers/router";
// import SignUpScreenController from "@/adapters/controllers/screen-sign-up-upload";

@injectable()
export default class SelectLoginInteractor {
  // _signUpScreenController: SignUpScreenController;
  _routerController: RouterController;

  constructor(
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._routerController = routerController;
  }

  async handle(): Promise<void> {
    this._routerController.pushLogin!();
  }
}
