import { injectable, inject } from "inversify";
import PasswordResetScreenController from "@/adapters/controllers/screen-password-reset";

@injectable()
export default class InitPasswordResetInteractor {
  _passwordResetScreenController: PasswordResetScreenController;

  constructor(
    @inject(PasswordResetScreenController)
    passwordResetScreenController: PasswordResetScreenController
  ) {
    this._passwordResetScreenController = passwordResetScreenController;
  }

  async handle(): Promise<void> {}
}
