import { injectable } from "inversify";
import ResultDetailsEmptyScreenData from "@/adapters/models/screen-result-details-empty";
import { Session } from "@/domain/users/models";

type EmptyAction = () => void;
type SetData = (data: ResultDetailsEmptyScreenData) => void;
type GetData = () => ResultDetailsEmptyScreenData;

@injectable()
export default class ResultDetailsEmptyScreenController {
  private cacheValidator?: string;
  private cache = new Map<string, ResultDetailsEmptyScreenData>();

  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;

  saveCache(id: string, userId: string) {
    if (userId) {
      this.cache.set(id, this.getData!());
      this.cacheValidator = userId;
    }
  }

  loadCache(
    id: string,
    userId: string
  ): ResultDetailsEmptyScreenData | undefined {
    if (this.cacheValidator == userId) {
      return this.cache.get(id);
    } else {
      return undefined;
    }
  }
}
