export const IS_PRODUCTION = process.env.NODE_ENV === "production";

export const SERVER_ADDRESS = IS_PRODUCTION
  ? "https://zeus.regiscrestani.com"
  : // : "https://api.dnaskills.bio";
    "http://localhost:8000";
    
// export const SERVER_ADDRESS = "http://localhost:8000";

export const STORAGE_LANG = "LANG";

export const PARAM_CHILD = "child";
