import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // =============================================================================
  // FULL PAGE LAYOUTS
  // =============================================================================
  {
    path: "",
    component: () => import("@/application/layouts/full-page/FullPage.vue"),
    children: [
      // =============================================================================
      // PAGES
      // =============================================================================
      {
        path: "/",
        name: "Welcome",
        component: () => import("@/application/screens/Welcome.vue")
      },
      {
        path: "/sign-up-age",
        name: "SignUpAge",
        component: () => import("@/application/screens/SignUpAge.vue")
      },
      {
        path: "/sign-up-upload",
        name: "SignUpUpload",
        component: () => import("@/application/screens/SignUpUpload.vue")
      },
      {
        path: "/sign-up-account/:id",
        name: "SignUpAccount",
        component: () => import("@/application/screens/SignUpAccount.vue")
      },
      {
        path: "/login",
        name: "Login",
        component: () => import("@/application/screens/Login.vue")
      },
      {
        path: "/password-reset/:code",
        name: "PasswordReset",
        component: () => import("@/application/screens/PasswordReset.vue")
      },
      {
        path: "/password-reset-request",
        name: "PasswordResetRequest",
        component: () =>
          import("@/application/screens/PasswordResetRequest.vue")
      }
    ]
  },
  {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
    path: "",
    component: () => import("@/application/layouts/main/Main.vue"),
    children: [
      // =============================================================================
      // Theme Routes
      // =============================================================================
      {
        path: "/overview",
        name: "Overview",
        component: () => import("@/application/screens/Overview.vue")
      },
      {
        path: "/overview2",
        name: "Overview2",
        component: () => import("@/application/screens/Overview2.vue")
      },
      {
        path: "/result-details/:id",
        name: "ResultDetails",
        component: () => import("@/application/screens/ResultDetails.vue")
      },
      {
        path: "/result-details-empty/:id",
        name: "ResultDetailsEmpty",
        component: () => import("@/application/screens/ResultDetailsEmpty.vue")
      },
      {
        path: "/account",
        name: "Account",
        component: () => import("@/application/screens/Account.vue")
      },
      {
        path: "/plan-code-activate",
        name: "PlanCodeActivate",
        component: () => import("@/application/screens/PlanCodeActivate.vue")
      },
      {
        path: "/researches",
        name: "Researches",
        component: () => import("@/application/screens/Researches.vue")
      }
    ]
  }

  // Redirect to 404 page, if no match found
  // {
  //   path: "*",
  //   redirect: "/error-404"
  // }
];

const router = new VueRouter({
  routes,
  // mode: "history",
  // base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

export default router;
