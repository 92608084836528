import { injectable, inject } from "inversify";
import OverviewScreenController from "@/adapters/controllers/screen-overview";
import { RouterController } from "@/adapters/controllers/router";
import { ICorrelationGateway } from "@/adapters/gateways/correlations";
import { ISessionGateway } from "@/adapters/gateways/users";

@injectable()
export default class SelectUpdateSoccerResultsInteractor {
  _overviewScreenController: OverviewScreenController;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;
  _correlationGateway: ICorrelationGateway;

  constructor(
    @inject(OverviewScreenController)
    overviewScreenController: OverviewScreenController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController,
    @inject("CorrelationGateway")
    correlationGateway: ICorrelationGateway
  ) {
    this._overviewScreenController = overviewScreenController;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
    this._correlationGateway = correlationGateway;
  }

  async handle(): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.user) {
      this._routerController.openWellcome!();
      return;
    }

    const isPlatinum = session.user!.plan!.plan.isPlatinum;

    if (isPlatinum) {
      let overviewScreenData = this._overviewScreenController.getData!();
      // this._overviewScreenController.setAsLoading!();
      overviewScreenData.soccerResults = await this._correlationGateway.myResults(
        session.tokens!,
        "38c2f7e6-0432-4cd7-bc64-b12fdf480bb5"
      );
      this._overviewScreenController.setData!(overviewScreenData);
      // this._overviewScreenController.setAsLoaded!();
    }
  }
}
