import { injectable } from "inversify";
import ResearchesScreenData from "@/adapters/models/screen-researches";

type EmptyAction = () => void;
type SetData = (data: ResearchesScreenData) => void;
type GetData = () => ResearchesScreenData;

@injectable()
export default class ResearchesScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
