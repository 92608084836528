import { injectable, inject } from "inversify";
import ResearchesScreenController from "@/adapters/controllers/screen-researches";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";
import { IResearchGateway } from "@/adapters/gateways/researches";

@injectable()
export default class SelectParticipateInteractor {
  _researchesScreenController: ResearchesScreenController;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;
  _researchGateway: IResearchGateway;

  constructor(
    @inject(ResearchesScreenController)
    researchesScreenController: ResearchesScreenController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController,
    @inject("ResearchGateway")
    researchGateway: IResearchGateway
  ) {
    this._researchesScreenController = researchesScreenController;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
    this._researchGateway = researchGateway;
  }

  async handle(id: string): Promise<void> {
    this._researchesScreenController.setAsLoading!();
    const session = this._sessionGateway.get();
    if (!session || !session.user) {
      this._routerController.openWellcome!();
      return;
    }
    const url = await this._researchGateway.volunteer(id, session);
    const newTab: any = window.open();
    newTab.location.href = url;
    const researches = await this._researchGateway.public(session);
    this._researchesScreenController.setData!({ researches });
    this._researchesScreenController.setAsLoaded!();
  }
}
