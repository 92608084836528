import { RouterController } from "@/adapters/controllers/router";
import ResultDetailsScreenController from "@/adapters/controllers/screen-result-details";
import { IReportVariantGateway } from "@/adapters/gateways/reports";
import { ISessionGateway } from "@/adapters/gateways/users";
import { inject, injectable } from "inversify";
import { Session } from "@/domain/users/models";

@injectable()
export default class InitResultDetailsScreenInteractor {
  _resultDetailsScreenController: ResultDetailsScreenController;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;
  _reportVariantGateway: IReportVariantGateway;

  constructor(
    @inject(ResultDetailsScreenController)
    _resultDetailsScreenController: ResultDetailsScreenController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject("ReportVariantGateway")
    reportVariantGateway: IReportVariantGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._resultDetailsScreenController = _resultDetailsScreenController;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
    this._reportVariantGateway = reportVariantGateway;
  }

  async handle(id: string): Promise<void> {
    this._resultDetailsScreenController.setAsLoading!();
    const session = this._sessionGateway.get();
    if (!session || !session.user) {
      this._routerController.openWellcome!();
      return;
    }

    const userId = session.user!.id;
    const cachedData = this._resultDetailsScreenController.loadCache(
      id,
      userId
    );
    if (cachedData) {
      this._resultDetailsScreenController.setData!(cachedData);
    } else {
      const reportVariant = await this._reportVariantGateway.details(
        id,
        session
      );
      this._resultDetailsScreenController.setData!({ reportVariant, session });
      this._resultDetailsScreenController.saveCache(id, userId);
    }
    this._resultDetailsScreenController.setAsLoaded!();
  }
}
