import { RouterController } from "@/adapters/controllers/router";
import SignUpAccountScreenController from "@/adapters/controllers/screen-sign-up-account";
import { IPlanGateway } from "@/adapters/gateways/plans";
import { ISessionGateway, IUserGateway } from "@/adapters/gateways/users";
import { Localization } from "@/commons/localization";
import { Session, UserSignIn, UserSignUp } from "@/domain/users/models";
import { inject, injectable } from "inversify";

@injectable()
export default class SubmitSignUpInteractor {
  _signUpAccountScreenController: SignUpAccountScreenController;
  _userGateway: IUserGateway;
  _planGateway: IPlanGateway;
  _sessionGateway: ISessionGateway;
  _localization: Localization;
  _routerController: RouterController;

  constructor(
    @inject(SignUpAccountScreenController)
    signUpAccountScreenController: SignUpAccountScreenController,
    @inject("UserGateway")
    userGateway: IUserGateway,
    @inject("PlanGateway")
    planGateway: IPlanGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject("Localization")
    localization: Localization,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._signUpAccountScreenController = signUpAccountScreenController;
    this._userGateway = userGateway;
    this._sessionGateway = sessionGateway;
    this._localization = localization;
    this._routerController = routerController;
    this._planGateway = planGateway;
  }

  async handle(userSignUp: UserSignUp, confirmPassword: string): Promise<void> {
    this._signUpAccountScreenController.resetFormErrors!();
    let isValid = true;

    if (!userSignUp.isNameValid()) {
      this._signUpAccountScreenController.setAsNameInvalid!();
      isValid = false;
    }
    if (!userSignUp.isEmailValid()) {
      this._signUpAccountScreenController.setAsEmailInvalid!();
      isValid = false;
    }
    if (!userSignUp.isPasswordValid()) {
      this._signUpAccountScreenController.setAsPasswordInvalid!();
      isValid = false;
    }
    if (userSignUp.password != confirmPassword) {
      this._signUpAccountScreenController.setAsConfirmPasswordInvalid!();
      isValid = false;
    }

    if (isValid) {
      this._signUpAccountScreenController.setAsLoading!();
      userSignUp.protect();
      try {
        await this._userGateway.signUp!(userSignUp);
      } catch (error) {
        if (error && error.response && error.response.status == 400) {
          if (error.response.data.email) {
            this._signUpAccountScreenController.setAsEmailAlreadyInUse!();
          }
        }
        this._signUpAccountScreenController.setAsLoaded!();
        return;
      }
      const tokens = await this._userGateway.signIn!(
        new UserSignIn(userSignUp.email, userSignUp.password, true)
      );
      const session: Session = { tokens };
      session.credential = await this._userGateway.me!(session);
      session.user = session.credential.user;
      session.user.plan = await this._planGateway.my!(session);
      this._sessionGateway.set(session);
      this._localization.set(session.user.language);
      this._routerController.pushOverview!();
    }
  }
}
