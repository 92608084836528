import { injectable, inject } from "inversify";
// import SignUpUploadScreenController from "@/adapters/controllers/screen-sign-up-upload";
import { RawData } from "@/domain/uploads/models";
import { RouterController } from "@/adapters/controllers/router";

@injectable()
export default class UploadRawDataSuccessInteractor {
  _routerController: RouterController;

  constructor(
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._routerController = routerController;
  }

  async handle(rawData: RawData): Promise<void> {
    this._routerController.pushSignUpAccount!(rawData.id);
  }
}
