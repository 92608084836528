import { injectable, inject } from "inversify";
// import WelcomeScreenController from "@/adapters/controllers/screen-welcome";
import { RouterController } from "@/adapters/controllers/router";

@injectable()
export default class SelectLoginInteractor {
  // _wellcomeScreenController: WelcomeScreenController;
  _routerController: RouterController;

  constructor(
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._routerController = routerController;
  }

  async handle(): Promise<void> {
    this._routerController.pushLogin!();
  }
}
