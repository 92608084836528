import { injectable } from "inversify";
import WelcomeScreenData from "@/adapters/models/screen-welcome";

type EmptyAction = () => void;
type SetData = (data: WelcomeScreenData) => void;
type GetData = () => WelcomeScreenData;

@injectable()
export default class WelcomeScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
