import { Entity } from "@/commons/models";
import { UserPlan } from "../plans/models";
import { interfaces } from "inversify";
import { PARAM_CHILD } from "@/commons/constants";
const jsSHA = require("jssha");

export class User extends Entity {
  name: string;
  email: string;
  language: string;
  plan?: UserPlan;
  children?: UserNode[];

  constructor(
    name: string,
    email: string,
    language: string,
    id?: string,
    createdAt?: Date,
    modifiedAt?: Date,
    disabledAt?: Date
  ) {
    super(id, createdAt, modifiedAt, disabledAt);
    this.name = name;
    this.email = email;
    this.language = language;
  }
}

export interface DashboardRawDataInfo {
  sourceType: number;
  extensionType: string;
  createdAt: string;
  isShared: boolean;
}
export interface DashboardDnaCounts {
  snps: number;
  genosets: number;
  genememosets: number;
}
export interface DashboardReportsCounts {
  available: number;
  locked: number;
}

export interface DashboardCheck {
  isRawDataProcessed: boolean;
}

export interface DashboardData {
  rawDataInfo: DashboardRawDataInfo;
  dnaCounts: DashboardDnaCounts;
  reportsCounts: DashboardReportsCounts;
  gender: number;
}

export class Credential extends Entity {
  user: User;

  constructor(
    user: User,
    id?: string,
    createdAt?: Date,
    modifiedAt?: Date,
    disabledAt?: Date
  ) {
    super(id, createdAt, modifiedAt, disabledAt);
    this.user = user;
  }
}

const EMAIL_RE = /^(.+)@(.+){2,}\.(.+){2,}$/;

function protectPassword(username: string, password: string): string {
  let sha = new jsSHA("SHA-512", "TEXT");
  sha.update(`${username}/${password}`);
  return sha.getHash("HEX");
}

export class UserSignUp {
  name: string;
  email: string;
  password: string;
  raw: string;
  language: string;
  birthDate: string;
  isProtected: boolean;

  constructor(
    name: string,
    email: string,
    password: string,
    raw: string,
    language: string,
    birthDate: string,
    isProtected: boolean = false
  ) {
    this.name = name;
    this.email = email;
    this.password = password;
    this.raw = raw;
    this.language = language;
    this.birthDate = birthDate;
    this.isProtected = isProtected;
  }

  isNameValid(): boolean {
    return this.name.length > 0;
  }

  isEmailValid(): boolean {
    return EMAIL_RE.test(this.email);
  }

  isPasswordValid(): boolean {
    return this.password.length > 0;
  }

  protect(): boolean {
    if (!this.isProtected && this.isEmailValid() && this.isPasswordValid()) {
      this.password = protectPassword(this.email, this.password);
      this.isProtected = true;
      return true;
    } else {
      return false;
    }
  }
}

export class UserSignIn {
  username: string;
  password: string;
  isProtected: boolean;

  constructor(
    username: string,
    password: string,
    isProtected: boolean = false
  ) {
    this.username = username;
    this.password = password;
    this.isProtected = isProtected;
  }

  isUsernameValid(): boolean {
    return EMAIL_RE.test(this.username);
  }

  isPasswordValid(): boolean {
    return this.password.length > 0;
  }

  protect(): boolean {
    if (!this.isProtected && this.isUsernameValid() && this.isPasswordValid()) {
      this.password = protectPassword(this.username, this.password);
      this.isProtected = true;
      return true;
    } else {
      return false;
    }
  }
}

export class UserPasswordResetRequest {
  email: string;

  constructor(email: string) {
    this.email = email;
  }

  isEmailValid(): boolean {
    return EMAIL_RE.test(this.email);
  }
}

export class UserPasswordReset {
  code: string;
  password: string;
  confirmPassword: string;

  constructor(code: string, password: string, confirmPassword: string) {
    this.code = code;
    this.password = password;
    this.confirmPassword = confirmPassword;
  }

  isPasswordValid(): boolean {
    return this.password.length > 0;
  }
}

export class UserAccountUpdate {
  name: string;
  language: string;

  constructor(name: string, language: string) {
    this.name = name;
    this.language = language;
  }

  isNameValid(): boolean {
    return this.name.length > 0;
  }
}

export class UserPasswordUpdate {
  username: string;
  currentPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
  isProtected: boolean;

  constructor(
    username: string,
    currentPassword: string,
    newPassword: string,
    newPasswordConfirm: string,
    isProtected: boolean = false
  ) {
    this.username = username;
    this.currentPassword = currentPassword;
    this.newPassword = newPassword;
    this.newPasswordConfirm = newPasswordConfirm;
    this.isProtected = isProtected;
  }

  isCurrentPasswordValid(): boolean {
    return this.currentPassword.length > 0;
  }
  isNewPasswordValid(): boolean {
    return this.newPassword.length > 0;
  }
  isNewPasswordConfirmValid(): boolean {
    return this.newPassword == this.newPasswordConfirm;
  }

  protect(): boolean {
    if (!this.isProtected) {
      this.currentPassword = protectPassword(
        this.username,
        this.currentPassword
      );
      this.newPassword = protectPassword(this.username, this.newPassword);
      this.isProtected = true;
      return true;
    } else {
      return false;
    }
  }
}
export class UserAccountDelete {
  username: string;
  password: string;
  isProtected: boolean;

  constructor(
    username: string,
    password: string,
    isProtected: boolean = false
  ) {
    this.username = username;
    this.password = password;
    this.isProtected = isProtected;
  }

  isPasswordValid(): boolean {
    return this.password.length > 0;
  }

  protect(): boolean {
    if (!this.isProtected) {
      this.password = protectPassword(this.username, this.password);
      this.isProtected = true;
      return true;
    } else {
      return false;
    }
  }
}

export class Tokens {
  access: string;
  refresh: string;

  constructor(access: string, refresh: string) {
    this.access = access;
    this.refresh = refresh;
  }

  getHeader() {
    return {
      Authorization: `Bearer ${this.access}`
    };
  }
}

export function buildParams(session: Session) {
  return {
    [PARAM_CHILD]: session.user!.id
  };
}

export interface Session {
  tokens?: Tokens;
  credential?: Credential;
  user?: User;
}

export class UserNode extends Entity {
  child?: User;
  constructor(
    id?: string,
    createdAt?: Date,
    modifiedAt?: Date,
    disabledAt?: Date
  ) {
    super(id, createdAt, modifiedAt, disabledAt);
  }
}
