import { injectable, inject } from "inversify";
import WelcomeScreenController from "@/adapters/controllers/screen-welcome";

@injectable()
export default class InitWelcomeScreenInteractor {
  _welcomeScreenController: WelcomeScreenController;

  constructor(
    @inject(WelcomeScreenController)
    welcomeScreenController: WelcomeScreenController
  ) {
    this._welcomeScreenController = welcomeScreenController;
  }

  async handle(): Promise<void> {}
}
