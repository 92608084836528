import { injectable, inject } from "inversify";
import SignUpScreenController from "@/adapters/controllers/screen-sign-up-upload";

@injectable()
export default class SelectTermsAndConditionsInteractor {
  _signUpScreenController: SignUpScreenController;

  constructor(
    @inject(SignUpScreenController)
    signUpScreenController: SignUpScreenController
  ) {
    this._signUpScreenController = signUpScreenController;
  }

  async handle(): Promise<void> {}
}
