import { injectable } from "inversify";
import AccountScreenData from "@/adapters/models/screen-account";

type EmptyAction = () => void;
type SetData = (data: AccountScreenData) => void;
type GetData = () => AccountScreenData;

@injectable()
export default class AccountScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
  setAsNameInvalid?: EmptyAction;
  setAsCurrentPasswordInvalid?: EmptyAction;
  setAsNewPasswordInvalid?: EmptyAction;
  setAsNewPasswordConfirmInvalid?: EmptyAction;
  setAsAccountUpdateSuccess?: EmptyAction;
  setAsPasswordUpdateSuccess?: EmptyAction;
  setAsDeletePasswordInvalid?: EmptyAction;
  setAsDeletePromptOpened?: EmptyAction;
  setAsDeletePromptClosed?: EmptyAction;
  resetFormErrors?: EmptyAction;
}
