import { injectable, inject } from "inversify";
import OverviewScreenController from "@/adapters/controllers/screen-overview";
import { ISessionGateway, IUserGateway } from "@/adapters/gateways/users";
import { IPlanGateway } from "@/adapters/gateways/plans";

@injectable()
export default class SelectAcceptRawDataUpgradeInteractor {
  _overviewScreenController: OverviewScreenController;
  _userGateway: IUserGateway;
  _planGateway: IPlanGateway;
  _sessionGateway: ISessionGateway;

  constructor(
    @inject(OverviewScreenController)
    overviewScreenController: OverviewScreenController,
    @inject("UserGateway")
    userGateway: IUserGateway,
    @inject("PlanGateway")
    planGateway: IPlanGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._overviewScreenController = overviewScreenController;
    this._planGateway = planGateway;
    this._sessionGateway = sessionGateway;
    this._userGateway = userGateway;
  }

  async handle(): Promise<void> {
    this._overviewScreenController.setAsLoading!();
    let session = this._sessionGateway.get();
    session.user!.plan = await this._planGateway.upgradeToRawData(session);
    let data = this._overviewScreenController.getData!();
    data.session = session;
    data.dashboard = await this._userGateway.dashboardData(session);
    this._overviewScreenController.setData!(data);
    this._sessionGateway.set(session);
    this._overviewScreenController.setAsLoaded!();
  }
}
