import { injectable, inject } from "inversify";
import PasswordResetScreenController from "@/adapters/controllers/screen-password-reset";
import { UserPasswordReset } from "@/domain/users/models";
import { IUserGateway } from "@/adapters/gateways/users";

@injectable()
export default class SubmitPasswordResetInteractor {
  _passwordResetScreenController: PasswordResetScreenController;
  _userGateway: IUserGateway;

  constructor(
    @inject(PasswordResetScreenController)
    passwordResetScreenController: PasswordResetScreenController,
    @inject("UserGateway")
    userGateway: IUserGateway
  ) {
    this._passwordResetScreenController = passwordResetScreenController;
    this._userGateway = userGateway;
  }

  async handle(request: UserPasswordReset): Promise<void> {
    this._passwordResetScreenController.clearFormErrors!();
    if (!request.isPasswordValid()) {
      this._passwordResetScreenController.setAsPasswordInvalid!();
      return;
    }
    if (request.password != request.confirmPassword) {
      this._passwordResetScreenController.setAsConfirmPasswordInvalid!();
      return;
    }
    this._passwordResetScreenController.setAsLoading!();
    await this._userGateway.passwordReset(request.code, request.password);
    this._passwordResetScreenController.setAsLoaded!();
    this._passwordResetScreenController.setAsSuccess!();
  }
}
