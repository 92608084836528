import { injectable, inject } from "inversify";
import OverviewScreenController from "@/adapters/controllers/screen-overview";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";
import { IReportVariantGateway } from "@/adapters/gateways/reports";
import { ReportVariant } from "@/domain/reports/models";

@injectable()
export default class SelectResultLearnMoreInteractor {
  _overviewScreenController: OverviewScreenController;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;
  _reportVariantGateway: IReportVariantGateway;

  constructor(
    @inject(OverviewScreenController)
    overviewScreenController: OverviewScreenController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject("ReportVariantGateway")
    reportVariantGateway: IReportVariantGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._overviewScreenController = overviewScreenController;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
    this._reportVariantGateway = reportVariantGateway;
  }

  async handle(reportVariant: ReportVariant): Promise<void> {
    this._routerController.pushResultDetails!(reportVariant.id);
  }
}
