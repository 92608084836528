import { injectable } from "inversify";
import PasswordResetRequestScreenData from "@/adapters/models/screen-password-reset-request";

type EmptyAction = () => void;
type SetData = (data: PasswordResetRequestScreenData) => void;
type GetData = () => PasswordResetRequestScreenData;

@injectable()
export default class PasswordResetRequestScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
  clearFormErrors?: EmptyAction;
  setAsEmailInvalid?: EmptyAction;
  setAsSuccess?: EmptyAction;
}
