import { injectable, inject } from "inversify";
import AccountScreenController from "@/adapters/controllers/screen-account";
import { IUserGateway, ISessionGateway } from "@/adapters/gateways/users";
import { UserAccountDelete } from "@/domain/users/models";
import { RouterController } from "@/adapters/controllers/router";

@injectable()
export default class SubmitAccountDeleteInteractor {
  _accountScreenController: AccountScreenController;
  _userGateway: IUserGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(AccountScreenController)
    accountScreenController: AccountScreenController,
    @inject("UserGateway")
    userGateway: IUserGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._accountScreenController = accountScreenController;
    this._userGateway = userGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(request: UserAccountDelete): Promise<void> {
    this._accountScreenController.resetFormErrors!();
    if (!request.isPasswordValid()) {
      this._accountScreenController.setAsDeletePasswordInvalid!();
      return;
    }
    this._accountScreenController.setAsLoading!();
    this._accountScreenController.setAsDeletePromptClosed!();
    let session = this._sessionGateway.get();
    request.protect();
    await this._userGateway.deleteAccount(request.password, session);
    if (session.credential!.user.id == session.user!.id) {
      this._sessionGateway.set({});
      this._routerController.openWellcome!();
    } else {
      session.credential = await this._userGateway.me(session);
      session.user = session.credential!.user;
      this._sessionGateway.set(session);
      this._routerController.openLogin!();
    }
  }
}
