import { injectable, inject } from "inversify";
import { SessionGateway } from "@/application/gateways/users";
import { User } from "@/domain/users/models";
import { RouterController } from "@/adapters/controllers/router";
import { Localization } from "@/commons/localization";

@injectable()
export default class SelectUserInteractor {
  _sessionGateway: SessionGateway;
  _routerController: RouterController;
  _localization: Localization;

  constructor(
    @inject("SessionGateway")
    sessionGateway: SessionGateway,
    @inject(RouterController)
    routerController: RouterController,
    @inject("Localization")
    localization: Localization
  ) {
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
    this._localization = localization;
  }

  async handle(user: User): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.user) {
      this._routerController.openWellcome!();
      return;
    }
    session.user = user;
    this._sessionGateway.set(session);
    this._localization.set(user.language);
    this._routerController.openLogin!();
  }
}
