import { injectable } from "inversify";

type EmptyAction = () => void;
type PushAction = (route: string) => void;
type OpenAction = (route: string) => void;
type IdAction = (id: string) => void;

@injectable()
export class RouterController {
  pop?: EmptyAction;
  push?: PushAction;
  open?: OpenAction;
  reload?: EmptyAction;
  pushWellcome?: EmptyAction;
  openWellcome?: EmptyAction;
  pushLogin?: EmptyAction;
  openLogin?: EmptyAction;
  pushSignUpUpload?: EmptyAction;
  openSignUpUpload?: EmptyAction;
  pushSignUpAccount?: IdAction;
  openSignUpAccount?: IdAction;
  pushOverview?: EmptyAction;
  openOverview?: EmptyAction;
  pushResultDetails?: IdAction;
  openResultDetails?: IdAction;
  pushResultDetailsEmpty?: IdAction;
  openResultDetailsEmpty?: IdAction;
  pushPasswordResetRequest?: EmptyAction;
  openPasswordResetRequest?: EmptyAction;
  pushPasswordReset?: EmptyAction;
  openPasswordReset?: EmptyAction;
  pushAccount?: EmptyAction;
  openAccount?: EmptyAction;
  pushSignUpAge?: EmptyAction;
  openSignUpAge?: EmptyAction;
  pushPlanCodeActivate?: EmptyAction;
  openPlanCodeActivate?: EmptyAction;
  pushResearches?: EmptyAction;
  openResearches?: EmptyAction;
}
