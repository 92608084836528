import { injectable, inject } from "inversify";
import OverviewScreenController from "@/adapters/controllers/screen-overview";

@injectable()
export default class SelectCloseDownloadInteractor {
  _overviewScreenController: OverviewScreenController;

  constructor(
    @inject(OverviewScreenController)
    overviewScreenController: OverviewScreenController
  ) {
    this._overviewScreenController = overviewScreenController;
  }

  async handle(): Promise<void> {
    let data = this._overviewScreenController.getData!();
    data.isWaitingDownload = false;
    this._overviewScreenController.setData!(data);
  }
}
