import { injectable, inject } from "inversify";
import ResultDetailsScreenController from "@/adapters/controllers/screen-result-details";

@injectable()
export default class SelectAvailableReportInteractor {
  _resultDetailsScreenController: ResultDetailsScreenController;

  constructor(
    @inject(ResultDetailsScreenController)
    resultDetailsScreenController: ResultDetailsScreenController
  ) {
    this._resultDetailsScreenController = resultDetailsScreenController;
  }

  async handle(): Promise<void> {}
}
