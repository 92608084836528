import * as robotsGateways from "@/application/gateways/robots";
import * as slotsGateways from "@/application/gateways/slots";
import * as snpsFrequenciesGateways from "@/application/gateways/snps-frequencies";
import Overview_SelectUpdateSoccerResultsInteractor from "@/business/overview/select-update-soccer-results";
import * as correlationsGateways from "@/application/gateways/correlations";
import Researches_SelectParticipateInteractor from "@/business/researches/select-participate";
import { RouterController } from "@/adapters/controllers/router";
import AccountScreenController from "@/adapters/controllers/screen-account";
import LoginScreenController from "@/adapters/controllers/screen-login";
import OverviewScreenController from "@/adapters/controllers/screen-overview";
import PasswordResetApplyScreenController from "@/adapters/controllers/screen-password-reset";
import PasswordResetRequestScreenController from "@/adapters/controllers/screen-password-reset-request";
import PlanCodeActivateScreenController from "@/adapters/controllers/screen-plan-code-activate";
import ResearchesScreenController from "@/adapters/controllers/screen-researches";
import ResultDetailsScreenController from "@/adapters/controllers/screen-result-details";
import ResultDetailsEmptyScreenController from "@/adapters/controllers/screen-result-details-empty";
import SignUpAccountScreenController from "@/adapters/controllers/screen-sign-up-account";
import SignUpAgeScreenController from "@/adapters/controllers/screen-sign-up-age";
import SignUpUploadScreenController from "@/adapters/controllers/screen-sign-up-upload";
import WelcomeScreenController from "@/adapters/controllers/screen-welcome";
import * as behavioursGateways from "@/application/gateways/behaviours";
import * as dnaGateways from "@/application/gateways/dna";
import * as plansGateways from "@/application/gateways/plans";
import * as reportsGateways from "@/application/gateways/reports";
import * as researchesGateways from "@/application/gateways/researches";
import * as uploadsGateways from "@/application/gateways/uploads";
import * as usersGateways from "@/application/gateways/users";
import * as webhooksGateways from "@/application/gateways/webhooks";
import * as workersGateways from "@/application/gateways/workers";
import Account_InitAccountInteractor from "@/business/account/init-account";
import Account_SelectAccountDeleteInteractor from "@/business/account/select-account-delete";
import Account_SubmitAccountInteractor from "@/business/account/submit-account";
import Account_SubmitAccountDeleteInteractor from "@/business/account/submit-account-delete";
import Account_SubmitPasswordUpdateInteractor from "@/business/account/submit-password-update";
import Login_InitLoginScreenInteractor from "@/business/login/init-login-screen";
import Login_SelectForgotPasswordInteractor from "@/business/login/select-forgot-password";
import Login_SelectSignUpInteractor from "@/business/login/select-sign-up";
import Login_SubmitLoginInteractor from "@/business/login/submit-login";
import NavBar_CreateUserChildrenInteractor from "@/business/nav-bar/create-user-children";
import NavBar_SelectLanguageInteractor from "@/business/nav-bar/select-language";
import NavBar_SelectLogoutInteractor from "@/business/nav-bar/select-logout";
import NavBar_SelectUserInteractor from "@/business/nav-bar/select-user";
import Overview_InitOverviewScreenInteractor from "@/business/overview/init-overview-screen";
import Overview_SelectAcceptRawDataUpgradeInteractor from "@/business/overview/select-accept-raw-data-upgrade";
import Overview_SelectAvailableReportInteractor from "@/business/overview/select-available-report";
import Overview_SelectCloseDownloadInteractor from "@/business/overview/select-close-download";
import Overview_SelectDownloadFullReportInteractor from "@/business/overview/select-download-full-report";
import Overview_SelectResultLearnMoreInteractor from "@/business/overview/select-result-learn-more";
import Overview_UpdateRawDataInteractor from "@/business/overview/update-raw-data";
import PasswordResetRequest_SelectLoginInteractor from "@/business/password-reset-request/select-login";
import PasswordResetRequest_SubmitPasswordResetRequestInteractor from "@/business/password-reset-request/submit-password-reset-request";
import PasswordReset_InitPasswordResetInteractor from "@/business/password-reset/init-password-reset";
import PasswordReset_SelectLoginInteractor from "@/business/password-reset/select-login";
import PasswordReset_SubmitPasswordResetInteractor from "@/business/password-reset/submit-password-reset";
import PlanCodeActivate_InitPlanCodeActivateScreenInteractor from "@/business/plan-code-activate/init-plan-code-activate-screen";
import PlanCodeActivate_SubmitPlanCodeInteractor from "@/business/plan-code-activate/submit-plan-code";
import Researches_InitResearchesScreenInteractor from "@/business/researches/init-researches-screen";
import ResultDetailsEmpty_InitResultDetailsEmptyScreenInteractor from "@/business/result-details-empty/init-result-details-empty-screen";
import ResultDetails_InitResultDetailsScreenInteractor from "@/business/result-details/init-result-details-screen";
import ResultDetails_SelectAvailableReportInteractor from "@/business/result-details/select-available-report";
import SignUpAccount_InitSignUpAccountScreenInteractor from "@/business/sign-up-account/init-sign-up-account-screen";
import SignUpAccount_SubmitSignUpInteractor from "@/business/sign-up-account/submit-sign-up";
import SignUpAge_SelectContinueInteractor from "@/business/sign-up-age/select-continue";
import SignUpAge_SelectReturnInteractor from "@/business/sign-up-age/select-return";
import SignUpUpload_InitSignUpUploadScreenInteractor from "@/business/sign-up-upload/init-sign-up-upload-screen";
import SignUpUpload_SelectLoginInteractor from "@/business/sign-up-upload/select-login";
import SignUpUpload_SelectPrivacyPolicyInteractor from "@/business/sign-up-upload/select-privacy-policy";
import SignUpUpload_SelectTermsAndConditionsInteractor from "@/business/sign-up-upload/select-terms-and-conditions";
import SignUpUpload_UploadRawDataFailInteractor from "@/business/sign-up-upload/upload-raw-data-fail";
import SignUpUpload_UploadRawDataSuccessInteractor from "@/business/sign-up-upload/upload-raw-data-success";
import Welcome_InitWelcomeScreenInteractor from "@/business/welcome/init-welcome-screen";
import Wellcome_SelectLoginInteractor from "@/business/welcome/select-login";
import Welcome_SelectSignUpInteractor from "@/business/welcome/select-sign-up";
import { Localization } from "@/commons/localization";
import { Container } from "inversify";

const container = new Container();

//{ GATEWAYS
container
  .bind("CredentialGateway")
  .to(usersGateways.CredentialServerGateway)
  .inSingletonScope();
container
  .bind(usersGateways.CredentialLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("RawDataGateway")
  .to(uploadsGateways.RawDataServerGateway)
  .inSingletonScope();
container
  .bind(uploadsGateways.RawDataLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("UserGateway")
  .to(usersGateways.UserServerGateway)
  .inSingletonScope();
container
  .bind("SessionGateway")
  .to(usersGateways.SessionGateway)
  .inSingletonScope();
container
  .bind("GenosetGateway")
  .to(dnaGateways.GenosetServerGateway)
  .inSingletonScope();
container
  .bind(dnaGateways.GenosetLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("SnpGateway")
  .to(dnaGateways.SnpServerGateway)
  .inSingletonScope();
container
  .bind(dnaGateways.SnpLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("GenotypeGateway")
  .to(dnaGateways.GenotypeServerGateway)
  .inSingletonScope();
container
  .bind(dnaGateways.GenotypeLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("BehaviourGateway")
  .to(behavioursGateways.BehaviourServerGateway)
  .inSingletonScope();
container
  .bind(behavioursGateways.BehaviourLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("UserBehaviourGateway")
  .to(behavioursGateways.UserBehaviourServerGateway)
  .inSingletonScope();
container
  .bind(behavioursGateways.UserBehaviourLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("BehaviourTypeGateway")
  .to(behavioursGateways.BehaviourTypeServerGateway)
  .inSingletonScope();
container
  .bind(behavioursGateways.BehaviourTypeLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("ReportGateway")
  .to(reportsGateways.ReportServerGateway)
  .inSingletonScope();
container
  .bind("ReportVariantGateway")
  .to(reportsGateways.ReportVariantServerGateway)
  .inSingletonScope();
container
  .bind(reportsGateways.ReportVariantLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("PlanGateway")
  .to(plansGateways.PlanServerGateway)
  .inSingletonScope();
container
  .bind("ReportPlanPermissionGateway")
  .to(reportsGateways.ReportPlanPermissionServerGateway)
  .inSingletonScope();
container
  .bind(reportsGateways.ReportPlanPermissionLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("UserGenosetGateway")
  .to(dnaGateways.UserGenosetServerGateway)
  .inSingletonScope();
container
  .bind(dnaGateways.UserGenosetLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("UserGenotypeGateway")
  .to(dnaGateways.UserGenotypeServerGateway)
  .inSingletonScope();
container
  .bind(dnaGateways.UserGenotypeLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("FigureGateway")
  .to(uploadsGateways.FigureServerGateway)
  .inSingletonScope();
container
  .bind(uploadsGateways.FigureLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("ResearchGateway")
  .to(researchesGateways.ResearchServerGateway)
  .inSingletonScope();
container
  .bind("DataSourceGateway")
  .to(researchesGateways.DataSourceServerGateway)
  .inSingletonScope();
container
  .bind(researchesGateways.DataSourceLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("ResearchDataSourceGateway")
  .to(researchesGateways.ResearchDataSourceServerGateway)
  .inSingletonScope();
container
  .bind(researchesGateways.ResearchDataSourceLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("ResearchResultColumnGateway")
  .to(researchesGateways.ResearchResultColumnServerGateway)
  .inSingletonScope();
container
  .bind(researchesGateways.ResearchResultColumnLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("ResearchResultRowGateway")
  .to(researchesGateways.ResearchResultRowServerGateway)
  .inSingletonScope();
container
  .bind(researchesGateways.ResearchResultRowLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("ResearchResultCellGateway")
  .to(researchesGateways.ResearchResultCellServerGateway)
  .inSingletonScope();
container
  .bind(researchesGateways.ResearchResultCellLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("ResearchResultCellUserGateway")
  .to(researchesGateways.ResearchResultCellUserServerGateway)
  .inSingletonScope();
container
  .bind(researchesGateways.ResearchResultCellUserLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("ResearchResultCellEntryGateway")
  .to(researchesGateways.ResearchResultCellEntryServerGateway)
  .inSingletonScope();
container
  .bind(researchesGateways.ResearchResultCellEntryLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("GlobalSnpVariantRateGateway")
  .to(dnaGateways.GlobalSnpVariantRateServerGateway)
  .inSingletonScope();
container
  .bind(dnaGateways.GlobalSnpVariantRateLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("GlobalSnpGateway")
  .to(dnaGateways.GlobalSnpServerGateway)
  .inSingletonScope();
container
  .bind(dnaGateways.GlobalSnpLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("WorkerProcessGateway")
  .to(workersGateways.WorkerProcessServerGateway)
  .inSingletonScope();
container
  .bind(workersGateways.WorkerProcessLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("WorkerTaskGateway")
  .to(workersGateways.WorkerTaskServerGateway)
  .inSingletonScope();
container
  .bind(workersGateways.WorkerTaskLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("EduzzTransactionGateway")
  .to(webhooksGateways.EduzzTransactionServerGateway)
  .inSingletonScope();
container
  .bind(webhooksGateways.EduzzTransactionLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("UserNodeGateway")
  .to(usersGateways.UserNodeServerGateway)
  .inSingletonScope();
container
  .bind(usersGateways.UserNodeLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("GenomemosetGateway")
  .to(dnaGateways.GenomemosetServerGateway)
  .inSingletonScope();
container
  .bind(dnaGateways.GenomemosetLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("ResearchDataSourceVolunteerGateway")
  .to(researchesGateways.ResearchDataSourceVolunteerServerGateway)
  .inSingletonScope();
container
  .bind(researchesGateways.ResearchDataSourceVolunteerLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("CorrelationGateway")
  .to(correlationsGateways.CorrelationServerGateway)
  .inSingletonScope();
container
  .bind("CorrelationGroupGateway")
  .to(correlationsGateways.CorrelationGroupServerGateway)
  .inSingletonScope();
container
  .bind(correlationsGateways.CorrelationGroupLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("CorrelationGroupUserGateway")
  .to(correlationsGateways.CorrelationGroupUserServerGateway)
  .inSingletonScope();
container
  .bind(correlationsGateways.CorrelationGroupUserLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("CorrelationControlResultsGateway")
  .to(correlationsGateways.CorrelationControlResultsServerGateway)
  .inSingletonScope();
container
  .bind(correlationsGateways.CorrelationControlResultsLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("CorrelationGroupResultsGateway")
  .to(correlationsGateways.CorrelationGroupResultsServerGateway)
  .inSingletonScope();
container
  .bind(correlationsGateways.CorrelationGroupResultsLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("CorrelationUserReportGateway")
  .to(correlationsGateways.CorrelationUserReportServerGateway)
  .inSingletonScope();
container
  .bind(correlationsGateways.CorrelationUserReportLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("CorrelationUserReportResultsGateway")
  .to(correlationsGateways.CorrelationUserReportResultsServerGateway)
  .inSingletonScope();
container
  .bind(correlationsGateways.CorrelationUserReportResultsLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("SnpFrequencyGateway")
  .to(snpsFrequenciesGateways.SnpFrequencyServerGateway)
  .inSingletonScope();
container
  .bind(snpsFrequenciesGateways.SnpFrequencyLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("SnpFrequencyReportGateway")
  .to(snpsFrequenciesGateways.SnpFrequencyReportServerGateway)
  .inSingletonScope();
container
  .bind(snpsFrequenciesGateways.SnpFrequencyReportLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("SnpInfoGateway")
  .to(snpsFrequenciesGateways.SnpInfoServerGateway)
  .inSingletonScope();
container
  .bind(snpsFrequenciesGateways.SnpInfoLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("SlotGateway")
  .to(slotsGateways.SlotServerGateway)
  .inSingletonScope();
container
  .bind(slotsGateways.SlotLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("SlotUserGateway")
  .to(slotsGateways.SlotUserServerGateway)
  .inSingletonScope();
container
  .bind(slotsGateways.SlotUserLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("RobotGateway")
  .to(robotsGateways.RobotServerGateway)
  .inSingletonScope();
container
  .bind(robotsGateways.RobotLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("RobotSampleGateway")
  .to(robotsGateways.RobotSampleServerGateway)
  .inSingletonScope();
container
  .bind(robotsGateways.RobotSampleLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("RobotTestGateway")
  .to(robotsGateways.RobotTestServerGateway)
  .inSingletonScope();
container
  .bind(robotsGateways.RobotTestLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("ReportPlusGateway")
  .to(reportsGateways.ReportPlusServerGateway)
  .inSingletonScope();
container
  .bind(reportsGateways.ReportPlusLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("ReportPlusTemplateGateway")
  .to(uploadsGateways.ReportPlusTemplateServerGateway)
  .inSingletonScope();
container
  .bind(uploadsGateways.ReportPlusTemplateLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("CustomReportGateway")
  .to(reportsGateways.CustomReportServerGateway)
  .inSingletonScope();
container
  .bind(reportsGateways.CustomReportLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("CustomReportBehaviourGateway")
  .to(reportsGateways.CustomReportBehaviourServerGateway)
  .inSingletonScope();
container
  .bind(reportsGateways.CustomReportBehaviourLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("CustomReportUserGateway")
  .to(reportsGateways.CustomReportUserServerGateway)
  .inSingletonScope();
container
  .bind(reportsGateways.CustomReportUserLocalGateway)
  .toSelf()
  .inSingletonScope();
//} GATEWAYS

//{ CONTROLLERS
container
  .bind(RouterController)
  .to(RouterController)
  .inSingletonScope();
container
  .bind(WelcomeScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(LoginScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(SignUpUploadScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(SignUpAccountScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(OverviewScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(ResultDetailsScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(PasswordResetRequestScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(PasswordResetApplyScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(AccountScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(SignUpAgeScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(PlanCodeActivateScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(ResearchesScreenController)
  .toSelf()
  .inSingletonScope();
//} CONTROLLERS

//{ INTERACTORS
container
  .bind(Wellcome_SelectLoginInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(Welcome_SelectSignUpInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(Login_SubmitLoginInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(Login_SelectForgotPasswordInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(Login_SelectSignUpInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(SignUpUpload_SelectLoginInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(SignUpUpload_SelectTermsAndConditionsInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(SignUpUpload_SelectPrivacyPolicyInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(SignUpUpload_UploadRawDataSuccessInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(SignUpUpload_UploadRawDataFailInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(SignUpAccount_SubmitSignUpInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(SignUpUpload_InitSignUpUploadScreenInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(SignUpAccount_InitSignUpAccountScreenInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(Login_InitLoginScreenInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(Welcome_InitWelcomeScreenInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(Overview_InitOverviewScreenInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(Overview_SelectResultLearnMoreInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ResultDetails_InitResultDetailsScreenInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(Overview_SelectAcceptRawDataUpgradeInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(Overview_SelectDownloadFullReportInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(Overview_SelectCloseDownloadInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(PasswordResetRequest_SubmitPasswordResetRequestInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(PasswordReset_SubmitPasswordResetInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(PasswordReset_InitPasswordResetInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(PasswordResetRequest_SelectLoginInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(PasswordReset_SelectLoginInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(Account_InitAccountInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(Account_SubmitAccountInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(Account_SubmitAccountDeleteInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(Account_SelectAccountDeleteInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(Account_SubmitPasswordUpdateInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(NavBar_SelectLanguageInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(NavBar_SelectLogoutInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ResultDetails_SelectAvailableReportInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(Overview_SelectAvailableReportInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ResultDetailsEmpty_InitResultDetailsEmptyScreenInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ResultDetailsEmptyScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(SignUpAge_SelectReturnInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(SignUpAge_SelectContinueInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(PlanCodeActivate_InitPlanCodeActivateScreenInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(PlanCodeActivate_SubmitPlanCodeInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(NavBar_CreateUserChildrenInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(NavBar_SelectUserInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(Overview_UpdateRawDataInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(Researches_InitResearchesScreenInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(Researches_SelectParticipateInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(Overview_SelectUpdateSoccerResultsInteractor)
  .toSelf()
  .inSingletonScope();
//} INTERACTORS

container
  .bind("Localization")
  .to(Localization)
  .inSingletonScope();

export default container;
