import { RouterController } from "@/adapters/controllers/router";
import ResearchesScreenController from "@/adapters/controllers/screen-researches";
import { IResearchGateway } from "@/adapters/gateways/researches";
import { ISessionGateway } from "@/adapters/gateways/users";
import { inject, injectable } from "inversify";

@injectable()
export default class InitResearchesScreenInteractor {
  _researchesScreenController: ResearchesScreenController;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;
  _researchGateway: IResearchGateway;

  constructor(
    @inject(ResearchesScreenController)
    researchesScreenController: ResearchesScreenController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController,
    @inject("ResearchGateway")
    researchGateway: IResearchGateway
  ) {
    this._researchesScreenController = researchesScreenController;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
    this._researchGateway = researchGateway;
  }

  async handle(): Promise<void> {
    this._researchesScreenController.setAsLoading!();
    const session = this._sessionGateway.get();
    if (!session || !session.user) {
      this._routerController.openWellcome!();
      return;
    }
    const researches = await this._researchGateway.public(session);
    this._researchesScreenController.setData!({ researches });
    this._researchesScreenController.setAsLoaded!();
  }
}
