import { injectable, inject } from "inversify";
import OverviewScreenController from "@/adapters/controllers/screen-overview";

@injectable()
export default class UpdateRawDataInteractor {
  _overviewScreenController: OverviewScreenController;

  constructor(
    @inject(OverviewScreenController)
    overviewScreenController: OverviewScreenController
  ) {
    this._overviewScreenController = overviewScreenController;
  }

  async handle(): Promise<void> {}
}
